
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import AddCategoryDrawer from '@/layout/header/partials/course/AddCategoryDrawer.vue';
import EditCategoryDrawer from '@/layout/header/partials/course/EditCategoryDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'course-alias',
  components: {
    Datatable,
    AddCategoryDrawer,
    EditCategoryDrawer,
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      tableHeader: [
        {
          name: 'Sales/ Purchase Type',
          key: 'category_name',
          // sortable: true,
        },
        {
          name: 'Product Type',
          key: 'product_type',
          // sortable: true,
        },
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '120px',
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      data: {},
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      await this.getCategoryData();
      this.emitter.on('category-added', async () => {
        await this.getCategoryData();
      });
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    editcategory(data) {
      console.log(data);
      this.emitter.emit('category-edit-type', data);
    },
    Deletecategory(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/category/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('category-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    async getCategoryData() {
      await ApiService.get('finmanInvoice/category/list')
        .then((response) => {
          this.tableData = response.data.data;

          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
  },
});
